import { Form, useActionData, useNavigation } from '@remix-run/react';
import { useForm } from '@shiftsmartinc/remix-utils';
import { typedjson } from 'remix-typedjson';
import { HEADER_COOKIE, HEADER_SET_COOKIE } from '@shiftsmartinc/remix-utils';
import { Button, Errors, Input } from '@shiftsmartinc/shiftsmart-ui';
import { redirect, type V2_MetaFunction } from '@remix-run/node';
import { FocusError } from 'focus-formik-error';
import { AuthSignInWithEmailDocument } from '~/__generated__/sdk';
import { getGraphql } from '~/global/utils/graphql';
import { GlobalHeader } from '~/global/components/GlobalHeader';
import { sessionUser } from '~/sessions';
import { signInForm, SignInForm } from '~/global/data/SignIn.form';
import { SITE_TITLE } from '~/global/config/site';

export async function action({ request }: { request: Request }) {
  const formData = await request.formData();

  const email = formData.get('email');
  const password = formData.get('password');
  const timeZone = formData.get('timeZone');

  if (!email || !password) {
    return typedjson({ error: 'Email and Password are required.' });
  }

  // 🔒 SOC2: Treat any error the same way, did you successfully login or not?
  const SOC2_GENERAL_ERROR = 'Invalid login, please check your credentials.';

  try {
    const header = request.headers.get(HEADER_COOKIE);
    const userSession = await sessionUser.getSession(header);

    const graphql = await getGraphql(request);

    const { data, error } = await graphql
      .mutation(AuthSignInWithEmailDocument, {
        signInEmailInput: {
          email: email?.toString(),
          password: password.toString(),
        },
      })
      .toPromise();

    if (error) return typedjson({ error: SOC2_GENERAL_ERROR });

    // 😕 Unknown | No data was returned, log it and return an generic error
    if (!data) return typedjson({ error: SOC2_GENERAL_ERROR });

    // 😄 Happy | We've accounted for additional flows, things were successful
    const { signInWithEmail } = data;
    const { accessToken, user } = signInWithEmail;

    // Save the the following values to our session cookie
    userSession.set('accessToken', accessToken);
    userSession.set('roles', user.roles);
    userSession.set('timeZone', timeZone);
    userSession.set('userId', user.uuid);

    return redirect(`/`, {
      headers: {
        [HEADER_SET_COOKIE]: await sessionUser.commitSession(userSession),
      },
    });
  } catch (error: unknown) {
    const values = Object.fromEntries(formData);
    const isError = error instanceof Error;
    const message = isError ? error.message : 'An unknown error has occurred.';

    return typedjson({ error: message, values });
  }
}

export const meta: V2_MetaFunction = () => {
  return [{ title: `Login | ${SITE_TITLE}` }];
};

export default function Auth() {
  // Hooks
  const actionData = useActionData();
  const navigation = useNavigation();
  const { formik, onSubmit } = useForm<SignInForm>(signInForm);

  // Setup
  const { errors, touched, values } = formik;

  // Setup
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const isLoading = navigation.state === 'loading';
  const isErrored = actionData && actionData.error;
  const isSubmitting = navigation.state === 'submitting';
  const isDisabled = isLoading || isSubmitting;

  // Handlers

  // Markup

  // Life Cycle

  // 🔌 Short Circuits

  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <Form
        className="mx-auto w-[320px]"
        method="POST"
        onSubmit={onSubmit}
        reloadDocument={true}
      >
        <FocusError formik={formik} />
        <input
          name="formName"
          readOnly={true}
          type="hidden"
          value={values.formName}
        />
        <GlobalHeader
          className="flex !justify-center border-b-0 px-0 shadow-none"
          heading="h1"
          title="Login to continue"
        />

        {/* Wrapping forms in a "fieldset" we can leverage the "disabled" prop */}
        <fieldset className="flex flex-col gap-2" disabled={isDisabled}>
          {/* Any errors from the submission process */}
          {isErrored && !isSubmitting && (
            <Errors
              className="mb-4 rounded-lg bg-error-lighter p-2 text-error-dark"
              errors={actionData.error}
              id="login-form"
            />
          )}

          {/* Basic form fields */}
          <Input
            data-testid="email"
            errors={touched.email ? errors.email : undefined}
            id="email"
            label="Email"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="text"
            value={values.email}
          />

          <Input
            data-testid="password"
            errors={touched.password ? errors.password : undefined}
            id="password"
            label="Password"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="password"
            value={values.password}
          />

          {/* Used for SSR + Client side hydration */}
          <Input id="timeZone" required={true} type="hidden" value={timeZone} />

          {/* Basic submit button */}
          <Button
            className="mt-4"
            data-testid="submit"
            loading={isSubmitting}
            type="submit"
          >
            Submit
          </Button>
        </fieldset>
      </Form>
    </div>
  );
}
